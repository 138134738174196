import React, { FunctionComponent, useContext } from "react";
import FullStory, { identify } from "react-fullstory";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from "react-router-dom";

import { EditEmployeeSchedulePane } from "~/components";
import { EmployeeReportsPane } from "~/components/EmployeeReportsPane/EmployeeReportsPane";
import { MutableSessionContext } from "~/lib/context/";
import { isAdminOrOwner, notEmployee } from "~/lib/employeeRoles";
import { PrivateRoute } from "~/lib/routing";
import { ErrorPageNotFound } from "~/ts-components/common/errors/ErrorPageNotFound";
import { TSFooter } from "~/ts-components/common/ts-footer/TSFooter";
import PermissionProvider from "~/ts-components/permissionProvider/PermissionProvider";
import { TSNavbar } from "~common";
import { EmployeeReportPane } from "~employees";
import { FormDetails, FormsSend } from "~forms";
import {
  Attendance,
  AttendanceInsights,
  Communicate,
  Employees,
  Forms,
  Portal,
  Reports,
  UserAccount
} from "~pages";

import "~styles/main.scss";
import "./Dashboard.scss";

export const Dashboard: FunctionComponent = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { session } = useContext(MutableSessionContext);
  const role = session.employee?.role;

  // Record the employee ID in FullStory so we can search / look up
  // But don't record any personal data (like email) so we don't have that data in FullStory
  if (session.employee) {
    identify(session.employee.id);
  }

  // We hide the footer on pages with sidebars. Pinning the footer to the bottom
  // on these pages has a negative impact on phone survey layout
  let displayFooter = true;
  const pagesWithSidebars = [
    "/dashboard/forms/form",
    "/dashboard/new-employees/employee"
  ];
  for (let i = 0; i < pagesWithSidebars.length; i++) {
    if (pathname.startsWith(pagesWithSidebars[i])) {
      displayFooter = false;
      break;
    }
  }

  return (
    <PermissionProvider permissions={session.permission_sets}>
      <div className="page-wrapper">
        <FullStory org={process.env.REACT_APP_FULLSTORY_ORG ?? ""} />
        <TSNavbar className="nav-fixer" />
        <main className="fixed-nav-offset">
          <Switch>
            <Route path={`${path}/new-reports`} component={Reports} exact />
            <Route path={`${path}/reports`} exact>
              <Redirect to={`${path}/attendance`} />
            </Route>
            <Route path={`${path}/attendance`} component={Attendance} exact />

            <PrivateRoute
              path={`${path}/attendance-insights`}
              component={AttendanceInsights}
              exact
              isAllowed={isAdminOrOwner(role)}
            />

            <PrivateRoute
              path={`${path}/new-employees/employee/:employeeId`}
              render={() => (
                <EmployeeReportPane parentUrl={`${path}/new-employees/`} />
              )}
              isAllowed={notEmployee(role)}
            />
            <PrivateRoute
              path={`${path}/employees`}
              component={Employees}
              exact
              isAllowed={notEmployee(role)}
            />
            <PrivateRoute
              path={`${path}/employees/employee/:employeeId/schedule`}
              component={EditEmployeeSchedulePane}
              isAllowed={notEmployee(role)}
            />
            <PrivateRoute
              path={`${path}/employees/employee/:employeeId/:tab`}
              component={EmployeeReportsPane}
              isAllowed={isAdminOrOwner(role)}
            />

            <PrivateRoute
              path={`${path}/forms`}
              component={Forms}
              exact
              isAllowed={isAdminOrOwner(role)}
            />
            <PrivateRoute
              path={`${path}/forms/form/:formId`}
              render={() => <FormDetails parentUrl={`${path}/forms/`} />}
              isAllowed={isAdminOrOwner(role)}
            />
            <PrivateRoute
              path={`${path}/forms/send/:templateId`}
              component={FormsSend}
              isAllowed={isAdminOrOwner(role)}
            />
            <PrivateRoute
              path={`${path}/communicate`}
              component={Communicate}
              exact
              isAllowed={isAdminOrOwner(role)}
            />
            <PrivateRoute
              path={`${path}/portal`}
              component={Portal}
              exact
              isAllowed={isAdminOrOwner(role)}
            />
            <PrivateRoute
              path={`${path}/account`}
              component={UserAccount}
              exact
              isAllowed={isAdminOrOwner(role)}
            />
            {/* Redirect from /dashboard for clarity. */}
            <Route path={`${path}`} exact>
              <Redirect to={`${path}/attendance`} />
            </Route>
            <Redirect
              from={`${path}/employees/employee/:employeeId`}
              to={`${path}/new-employees/employee/:employeeId`}
            />
            <Route component={ErrorPageNotFound} />
          </Switch>

          <div id="ts-overlays" className="ts"></div>
        </main>
        {displayFooter && <TSFooter />}
      </div>
    </PermissionProvider>
  );
};
