import React, { FunctionComponent, useContext, useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import clsx from "clsx";

import BrandWhite from "~/images/brand-white.svg";
import { MutableSessionContext } from "~/lib/context";
import {
  isAdminOrOwner,
  notEmployee,
  notTeamLeader
} from "~/lib/employeeRoles";
import "./TSNavbar.scss";

export const TSNavbar: FunctionComponent<TSNavbarProps> = ({
  className
}): JSX.Element => {
  const { session } = useContext(MutableSessionContext);
  const role = session.employee?.role;
  const showCommunicateTab = isAdminOrOwner(role);
  const showEmployeeTab = notEmployee(role);
  const showFormTab = isAdminOrOwner(role);
  const showPortalTab = isAdminOrOwner(role);

  const { attendance } = session.features;
  const showAttendanceTab = attendance;

  const showAttendanceInsightsTab = notTeamLeader(role);
  const helpUrl = "https://help.teamsense.com";

  const { pathname } = useLocation();
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  // Content Fragments
  const navbarInitials = (
    <span className="navbar-initials">
      {session?.employee?.given_name[0]}
      {session?.employee?.family_name[0]}
    </span>
  );

  const navbarUserName = (
    <span className="navbar-user-name">{session?.employee?.name}</span>
  );

  const helpButtonContent = (
    <Nav.Item className="navbar-help">
      <span className="navbar-help-label">Help</span>
      <i className="icon-help"></i>
    </Nav.Item>
  );

  const userButtonContent = (
    <Nav.Item className="navbar-user">
      {navbarUserName}
      {navbarInitials}
      <i className="icon-caret-bottom" />
    </Nav.Item>
  );

  const menuDropdownContent = (title: string): JSX.Element => (
    <Nav.Item className="navbar-user">
      {title}
      <i className="icon-caret-bottom" />
    </Nav.Item>
  );

  const leftLinks = (
    <Nav className="left-links">
      {showAttendanceTab && (
        <>
          {showAttendanceInsightsTab ? (
            <NavDropdown
              title={menuDropdownContent("Attendance")}
              active={pathname.includes("/attendance")}
            >
              <NavDropdown.Item
                as={Link}
                to="/dashboard/attendance"
                active={
                  pathname.includes("/attendance") &&
                  !pathname.includes("/attendance-insights")
                }
              >
                Summary
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/dashboard/attendance-insights"
                active={pathname.includes("/attendance-insights")}
              >
                Insights
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Nav.Link
              as={Link}
              to="/dashboard/attendance"
              active={
                pathname.includes("/attendance") &&
                !pathname.includes("/attendance-insights")
              }
            >
              Attendance
            </Nav.Link>
          )}
        </>
      )}
      {showEmployeeTab && (
        <Nav.Link
          as={Link}
          to="/dashboard/employees"
          eventKey="employees"
          active={pathname.includes("/employees")}
        >
          Employees
        </Nav.Link>
      )}
      {showFormTab && (
        <Nav.Link
          as={Link}
          to="/dashboard/forms"
          eventKey="forms"
          active={pathname.includes("/forms")}
        >
          Forms
        </Nav.Link>
      )}
      {showCommunicateTab && (
        <Nav.Link
          as={Link}
          to="/dashboard/communicate"
          eventKey="communicate"
          active={pathname.includes("/communicate")}
        >
          Communicate
        </Nav.Link>
      )}
      {showPortalTab && (
        <Nav.Link
          as={Link}
          to="/dashboard/portal"
          eventKey="portal"
          active={pathname.includes("/portal")}
        >
          Portal
        </Nav.Link>
      )}
    </Nav>
  );

  const rightLinks = (
    <Nav className="right-links">
      <Nav.Link href={helpUrl}>{helpButtonContent}</Nav.Link>
      <NavDropdown title={userButtonContent} align="end">
        <NavDropdown.Item as={Link} to="/dashboard/account">
          Manage Account
        </NavDropdown.Item>{" "}
        <NavDropdown.Item as={Link} to="/logout">
          Sign Out
        </NavDropdown.Item>{" "}
      </NavDropdown>
    </Nav>
  );

  const bottomOffcanvasLinks = (
    <Nav>
      <Nav.Link href={helpUrl}>Help</Nav.Link>
      <Nav.Link as={Link} to="/dashboard/account" eventKey="account">
        Manage Account
      </Nav.Link>
      <Nav.Link as={Link} to="/logout" eventKey="logout">
        Sign Out
      </Nav.Link>
    </Nav>
  );

  return (
    <div className={clsx("ts", className)}>
      <Navbar
        className="ts-navbar"
        bg="secondary"
        expanded={showOffcanvas}
        onToggle={setShowOffcanvas}
        collapseOnSelect
        expand="lg"
        variant="dark"
      >
        <Container fluid>
          <Navbar.Toggle aria-controls="offcanvasNavbar">
            <i className="icon-hamburger" />
          </Navbar.Toggle>
          <Navbar.Brand href="/">
            <img className="teamsense-logo" src={BrandWhite} alt="TeamSense" />
          </Navbar.Brand>
          <Nav className="collapsed-help">
            <Nav.Link href={helpUrl}>
              <i className="icon-help" />
            </Nav.Link>
          </Nav>
          <Navbar.Collapse>
            {leftLinks}
            {rightLinks}
          </Navbar.Collapse>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
            show={showOffcanvas}
            container={document.getElementById("ts-overlays")}
          >
            <Offcanvas.Header>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <button
                  className="offcanvas-close"
                  aria-label="Close"
                  onClick={() => setShowOffcanvas(false)}
                >
                  <i className="icon-close" />
                </button>
                {navbarInitials}
                {navbarUserName}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {leftLinks}
              <hr className="offcanvas-line-break" />
              {bottomOffcanvasLinks}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
};

interface TSNavbarProps {
  className?: string;
}
